/**
 * Vacancy filtering system.
 *
 * @author Louis Young
 */

/**
 * Screen.
 *
 * DOM manipulation.
 */

class UI {
  static renderVacancies(vacancies) {
    const results = document.querySelector(".vacancies__list");

    if (vacancies == undefined) {
      vacancies = '<p class="no-results">No results.</p>';
    }

    results.innerHTML = vacancies;
  }

  static updateVacancyCounts(counts) {
    const privateHouseholds = document.querySelector(".private-households");

    privateHouseholds.innerHTML = counts.privateHouseholds;

    const privateJets = document.querySelector(".private-jets");

    privateJets.innerHTML = counts.privateJets;

    const privateYachts = document.querySelector(".private-yachts");

    privateYachts.innerHTML = counts.privateYachts;

    const familyOffices = document.querySelector(".family-offices");

    familyOffices.innerHTML = counts.familyOffices;
  }
}

/**
 * Ajax.
 *
 * Async JS & XML.
 */

class Ajax {
  /**
   * POST request.
   *
   * @param data
   */

  static post(data) {
    // const url = "http://localhost/site/templates/includes/_filter.php";

    // prettier-ignore
    const url = "https://www.luxuryassets.co.uk/site/templates/includes/_filter.php/";

    // Create a formData object to post so we have a POST variable to receive.
    const formData = new FormData();

    formData.append("filterData", data);

    fetch(url, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        UI.renderVacancies(data.vacancies);
        // UI.updateVacancyCounts(data.vacancyCounts);
        // console.log(data.vacancyCounts);
      })
      .catch(error => {
        console.error(error);
      });
  }
}

/**
 * Filter.
 */

class Filter {
  constructor(element) {
    this.sidebar = document.querySelector(element);
    this.initialise();
  }

  initialise() {
    // Ajax.post(this.filters);

    this.sidebar.addEventListener("change", () => {
      Ajax.post(this.filters);
    });
  }

  get inputs() {
    return this.sidebar.querySelectorAll(".filter__input");
  }

  get filters() {
    const filterAttributes = [];

    this.inputs.forEach(input => {
      if (input.checked) {
        filterAttributes.push(input.getAttribute("data-filter"));
      }
    });

    // Map a new array in a suitable format for the Processwire API.
    const filterQuery = filterAttributes.map(index => `${index}=1`);

    return filterQuery.toString();
  }
}

if (document.querySelector(".sidebar")) {
  const filter = new Filter(".sidebar");
}
