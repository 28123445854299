!(function(t, e) {
  typeof define == "function" && define.amd    ? define([], e())    : typeof module == "object" && module.exports    ? (module.exports = e())    : (function n() {
        document && document.body ? (t.zenscroll = e()) : setTimeout(n, 9);
      })();
})(this, () => {
  "use strict";
  let t = function(t) {
    return (
      t &&      "getComputedStyle" in window &&      window.getComputedStyle(t)["scroll-behavior"] === "smooth"
    );
  };

  if (typeof window == "undefined" || !("document" in window)) {
    return {};
  }
  let e = function(e, n, o) {
      (n = n || 999), o || o === 0 || (o = 9);
      let i,
        r = function(t) {
          i = t;
        },
        u = function() {
          clearTimeout(i), r(0);
        },
        c = function(t) {
          return Math.max(0, e.getTopOf(t) - o);
        },
        a = function(o, i, c) {
          if ((u(), i === 0 || (i && i < 0) || t(e.body))) {
            e.toY(o), c && c();
          } else {
            let a = e.getY(),
              f = Math.max(0, o) - a,
              s = new Date().getTime();

            (i = i || Math.min(Math.abs(f), n)),
              (function t() {
                r(
                  setTimeout(() => {
                    let n = Math.min(1, (new Date().getTime() - s) / i),
                      o = Math.max(
                        0,
                        Math.floor(
                          a + f * (n < 0.5 ? 2 * n * n : n * (4 - 2 * n) - 1)
                        )
                      );

                    e.toY(o),
                      n < 1 && e.getHeight() + o < e.body.scrollHeight                        ? t()                        : (setTimeout(u, 99), c && c());
                  }, 9)
                );
              })();
          }
        },
        f = function(t, e, n) {
          a(c(t), e, n);
        },
        s = function(t, n, i) {
          let r = t.getBoundingClientRect().height,
            u = e.getTopOf(t) + r,
            s = e.getHeight(),
            l = e.getY(),
            d = l + s;

          c(t) < l || r + o > s            ? f(t, n, i)            : u + o > d            ? a(u - s + o, n, i)            : i && i();
        },
        l = function(t, n, o, i) {
          a(
            Math.max(
              0,
              e.getTopOf(t) -                e.getHeight() / 2 +                (o || t.getBoundingClientRect().height / 2)
            ),
            n,
            i
          );
        };

      return {
        setup: function(t, e) {
          return (
            (t === 0 || t) && (n = t),
            (e === 0 || e) && (o = e),
            { defaultDuration: n, edgeOffset: o }
          );
        },
        to: f,
        toY: a,
        intoView: s,
        center: l,
        stop: u,
        moving: function() {
          return !!i;
        },
        getY: e.getY,
        getTopOf: e.getTopOf
      };
    },
    n = document.documentElement,
    o = function() {
      return window.scrollY || n.scrollTop;
    },
    i = e({
      body: document.scrollingElement || document.body,
      toY: function(t) {
        window.scrollTo(0, t);
      },
      getY: o,
      getHeight: function() {
        return window.innerHeight || n.clientHeight;
      },
      getTopOf: function(t) {
        return t.getBoundingClientRect().top + o() - n.offsetTop;
      }
    });

  if (
    ((i.createScroller = function(t, o, i) {
      return e(
        {
          body: t,
          toY: function(e) {
            t.scrollTop = e;
          },
          getY: function() {
            return t.scrollTop;
          },
          getHeight: function() {
            return Math.min(
              t.clientHeight,
              window.innerHeight || n.clientHeight
            );
          },
          getTopOf: function(t) {
            return t.offsetTop;
          }
        },
        o,
        i
      );
    }),
    "addEventListener" in window && !window.noZensmooth && !t(document.body))
  ) {
    let r = "history" in window && "pushState" in history,
      u = r && "scrollRestoration" in history;

    u && (history.scrollRestoration = "auto"),
      window.addEventListener(
        "load",
        () => {
          u &&            (setTimeout(() => {
              history.scrollRestoration = "manual";
            }, 9),
            window.addEventListener(
              "popstate",
              t => {
                t.state && "zenscrollY" in t.state && i.toY(t.state.zenscrollY);
              },
              !1
            )),
            window.location.hash &&              setTimeout(() => {
                let t = i.setup().edgeOffset;

                if (t) {
                  let e = document.getElementById(
                    window.location.href.split("#")[1]
                  );

                  if (e) {
                    let n = Math.max(0, i.getTopOf(e) - t),
                      o = i.getY() - n;

                    o >= 0 && o < 9 && window.scrollTo(0, n);
                  }
                }
              }, 9);
        },
        !1
      );
    let c = new RegExp("(^|\\s)noZensmooth(\\s|$)");

    window.addEventListener(
      "click",
      t => {
        for (var e = t.target; e && e.tagName !== "A"; ) {
          e = e.parentNode;
        }
        if (
          !(
            !e ||            t.which !== 1 ||            t.shiftKey ||            t.metaKey ||            t.ctrlKey ||            t.altKey
          )
        ) {
          if (u) {
            let n =              history.state && typeof history.state == "object"                ? history.state                : {};

            n.zenscrollY = i.getY();
            try {
              history.replaceState(n, "");
            } catch (t) {}
          }
          let o = e.getAttribute("href") || "";

          if (o.indexOf("#") === 0 && !c.test(e.className)) {
            let a = 0,
              f = document.getElementById(o.substring(1));

            if (o !== "#") {
              if (!f) {
                return;
              }
              a = i.getTopOf(f);
            }
            t.preventDefault();
            let s = function() {
                window.location = o;
              },
              l = i.setup().edgeOffset;

            l &&              ((a = Math.max(0, a - l)),
              r &&                (s = function() {
                  history.pushState({}, "", o);
                })),
              i.toY(a, null, s);
          }
        }
      },
      !1
    );
  }
  return i;
});
